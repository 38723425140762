$secondary-color: rgb(39, 42, 47);
$secondary-muted-color: hsl(210, 25%, 20%);
$secondary-text: #dcdcdc;

$accent-color: rgb(82, 85, 90);
$accent-text: #fff;

$accent-text-active: #0a6ebd;

$background-color: #f2f2f2;
$content-background: #fcfcfc;
$overlay-color: #000;
$timeline-background-color: #f4f4f4;

$text-color: #000;
$text-subtle-color: #8c8c8c;
$text-subtle-accent: #164f7a;

$user-thumb-color: rgb(204, 204, 204);

$pink: rgba(239, 0, 127, 1);
$toast-badge-color: #f00;

$table-alt-row-color: rgba($accent-color, 0.1);
$table-hover-row-color: rgba($accent-color, 0.3);

$like-color: #5692ce;

$clickable-read-only-field: #fff;

$popover-border-color: #e8e8e8;

// sizings
$side-bar-width: 250px;
$top-bar-height: 70px;

// breakpoints
$break-x-x-small: 380px;
$break-x-small: 480px;
$break-small: 768px;
$break-medium: 992px;
$break-large: 1200px;
$break-x-large: 1400px;

$animation-timing: 0.3s;
