.ReactTable .rt-thead .rt-th {
  background-color: rgb(82, 85, 90);
  color: #fff;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead.-filters .rt-th {
  border-color: #f2f2f2;
}

.ReactTable .rt-thead .rt-th input,
.ReactTable .rt-thead.-filters .rt-th input {
  color: #000;
}

.grid-header-cell {
  background-color: $accent-color;
}

.grid-cell {
  &.grid-row-even {
    background-color: $table-alt-row-color;
  }

  &.grid-row-hovered {
    background-color: $table-hover-row-color;
  }
}

.grid-filter-indicator {
  &.active {
    border: 1px solid $accent-text-active;
    color: $accent-text-active;
  }
}

.grid-container .grid-footer {
  background-color: $accent-color;
  border: 1px solid $accent-text;
}

.no-rows {
  color: $text-subtle-color;
}

.scroll-x-indicator {
  box-shadow: -1px 3px 3px $text-subtle-color;
}
