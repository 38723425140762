.form-horizontal {
  max-width: 750px;

  .row {
    margin-bottom: 5px;
    margin-left: 0;
    margin-right: 0;
  }

  &.login-form {
    // @media screen and (min-width: $break-medium) {
    //   margin: 0 auto;
    //   max-width: $break-small;
    // }
  }
}

.form-group {
  margin-bottom: 5px;
}

// stylelint-disable-next-line selector-class-pattern
.Select-menu-outer {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.tether-element {
  z-index: 10000;
}

.select-addon-before .input-group {
  // stylelint-disable-next-line selector-class-pattern
  .Select:first-of-type {
    float: left;
    width: 100px;

    // stylelint-disable-next-line
    .Select-control {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  // stylelint-disable selector-class-pattern
  input,
  .Select:not(:first-of-type),
  .form-control {
    float: left;
    height: 36px;
    width: calc(100% - 100px);
  }

  .Select:not(:first-of-type) {
    // stylelint-disable-next-line
    .Select-control {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

.select-addon-after .input-group {
  // stylelint-disable selector-class-pattern
  input,
  .Select:not(:first-of-type),
  .form-control {
    float: left;
    height: 36px;
    width: calc(100% - 100px);
  }

  .Select:first-of-type {
    // stylelint-disable-next-line
    .Select-control {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.select-addon-before .template-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 99px;
}

.template-input-panel {
  bottom: 34px;
  position: absolute;
  width: calc(100% - 30px);
  z-index: 20;

  .panel-body {
    padding-bottom: 2px;
    padding-top: 2px;
  }

  .panel-heading {
    background-color: $accent-color;
    color: $accent-text;
    font-weight: bold;
  }

  .option-scroller {
    max-height: 250px;
    overflow: auto;
  }

  .template-option {
    padding-bottom: 3px;
    padding-top: 3px;

    &:hover {
      background-color: $accent-color;
      color: $accent-text;
      cursor: pointer;
    }
  }
}

.control-label {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 5px;
  max-width: 100%;
}

// pads the size of a label on medium screens (when it gets offset)
@media screen and (max-width: $break-medium) {
  .form-pad-label {
    margin-top: 26px;
  }
}
