.toolbar {
  line-height: 24px;
  padding: 3px;
  width: 100%;
}

.toolbar > div {
  margin-right: 5px;
}

.toolbar .input-xs {
  height: 22px;
}

.ReactTable .rt-td {
  padding: 3px;
}

.ReactTable .rt-th [class^='col-'] {
  padding: 0;
}

.ReactTable .rt-th .date-filter input {
  height: 20px;
}

.ReactTable .rt-th .date-filter .form-group {
  margin-bottom: 5px;
}

.ReactTable .rt-table {
  overflow: hidden;
  min-height: 70px;
}

.ReactTable .rt-thead {
  min-width: auto !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.ReactTable .rt-tbody {
  min-width: auto !important;
  overflow-y: scroll;
}

.ReactTable .rt-tr-group {
  width: fit-content;
  flex: initial;
}

.ReactTable .rt-thead .rt-th {
  background-color: rgb(82, 85, 90);
  color: #fff;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead.-filters .rt-th {
  border-color: #f2f2f2;
}

.ReactTable .rt-thead .rt-th input,
.ReactTable .rt-thead.-filters .rt-th input {
  color: #000;
}

.ReactTable .-pagination .-center {
  min-width: 250px;
}
