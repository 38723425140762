.vault-filter {
  .form-field {
    margin-bottom: 5px;
  }
}

.show-execution {
  .vault-filter .control-label {
    height: auto;
    line-height: 15px;
  }
}
