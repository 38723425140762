@import './Variables';
@import './Layout';
@import './DuoOnboarding';
@import './Components';
@import './FileUploadModal';
@import './DataGrid';
@import './Homepage';
@import './Diagnostic';
@import './Docs';
@import './StepTypeModal';
@import './ActiveExecutions';
@import './Vault';
@import './Dashboard';
@import './ShowProcess';
@import './Form/ReactWidgetsOverride';
@import './Form/Form';
@import './Form/Help';
@import './Form/Loading';

@import './Marketplace';
@import './SiteDocs';
@import './ShowProcess';
@import './ShowExecution/ShowExecution';
@import './Forms/ProcessForm';
@import './Forms/ShowUser';
@import './Forms/Integrations';
@import './Forms/Login';
@import './Forms/InviteUser';

@import './Override.scss';

// TEMP
.mockup-image {
  margin-top: 20px;
  overflow: hidden;
  width: 100%;

  img {
    margin-left: -205px;
    margin-top: -200px;
    width: calc(100% + 200px);
  }
}
