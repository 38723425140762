.active-execution-list-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;

  .toolbar {
    .loading-indicator {
      margin-top: 10px;
      padding-left: 25px;
    }

    .fa-spinner {
      font-size: 18px;
    }
  }

  .active-execution-list {
    flex: 1;
    overflow-y: auto;
  }
}

.paginator {
  padding: 5px;

  .pagination {
    float: right;
    margin: 0;
  }

  .paginator-label {
    float: left;
    margin-right: 10px;
    margin-top: 5px;
  }
}

.active-execution-list-item {
  border: solid 1px $text-subtle-color;
  margin: 10px;
  padding: 10px;
  width: calc(100% - 20px);

  .progress {
    margin-bottom: 3px;
  }
}
