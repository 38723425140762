.step-type-modal {
  .step-type-col,
  .step-details-col {
    height: 600px;
    overflow-y: auto;
  }

  @media only screen and (max-width: $break-small) {
    .step-type-col,
    .step-details-col {
      height: 350px;
    }
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
  }
}
