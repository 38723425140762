.site-docs-filter {
  .form-group {
    margin-bottom: 0;
  }

  .control-label {
    text-align: center;
  }
}

.site-docs {
  .grid-row {
    flex: 1;
    flex-shrink: 0;
  }
}
