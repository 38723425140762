.file-upload-modal {
  .dropzone {
    width: 100%;
  }

  .upload-loading {
    font-size: 1.5em;
    width: 100%;
  }

  .upload-spacer {
    height: 75px;
  }

  .drag-indicator {
    align-items: center;
    color: $text-subtle-color;
    display: flex;
    font-size: 3em;
    height: 75px;
    justify-content: center;

    i {
      margin-right: 15px;
    }
  }
}

.upload-preview {
  border: 1px solid $text-subtle-color;
  border-radius: 4px;
  display: block;
  line-height: 1.42857143;
  margin-bottom: 20px;
  padding: 10px;
  transition: border 0.2s ease-in-out;
}

.upload {
  border: 1px solid $text-subtle-color;
  border-radius: 4px;
  display: block;
  line-height: 1.42857143;
  margin-bottom: 20px;
  padding: 10px;
  transition: border 0.2s ease-in-out;
  width: 200px;
}

.upload-thumbnail img {
  max-width: 100%;
}
