.struct-data-details {
  max-height: 250px;
  overflow: auto;
  // border-bottom: 1px solid $secondary-text;

  h3 {
    margin-top: 0;
  }

  .row {
    margin-bottom: 5px;
  }

  .computation-label,
  .attribute-label {
    text-transform: capitalize;
  }

  .attribute-label {
    color: $text-subtle-color;
  }

  .computation-label {
    color: $text-subtle-accent;
  }

  .computation-data,
  .attribute-data {
    color: $text-color;
    max-height: 150px;
    overflow: auto;
    word-wrap: break-word;

    ul {
      padding-left: 20px;
    }
  }

  .computation-data {
    color: $accent-text-active;
  }
}

.struct-data-header {
  .btn {
    margin-right: 5px;

    &:first-child {
      margin-right: 0;
    }
  }

  .fa-lock {
    color: $text-subtle-color;
    display: inline-block;
    font-size: 18px;
    margin-left: 10px;
  }
}

.toolbar .dropdown-menu {
  max-height: 300px;
  overflow: auto;
}

.overflow-y-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.struct-data-processes {
  border-bottom: 1px solid $secondary-text;
}

.struct-form-row {
  @media screen and (max-width: $break-medium) {
    border-bottom: 1px solid $background-color;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}

.vault-table-toggle {
  .form-group {
    margin-bottom: 0;

    > div {
      padding: 0;
    }
  }

  &.row > div {
    padding-left: 0;
  }

  .Select-control {
    height: 24px;

    .Select-placeholder {
      line-height: 20px;
    }

    .Select-value {
      line-height: 20px !important;

      .Select-value-label {
        line-height: 20px;
      }
    }

    .Select-input {
      height: 20px;
      line-height: 20px !important;
    }
  }
}
