// stylelint-disable selector-max-compound-selectors, selector-class-pattern
.markdown-badge {
  color: #575f67;
  cursor: pointer;
  display: inline-block;
  background: #e6f3ff;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
  text-decoration: none;
}

.progress {
  margin-bottom: 0;
}

.content-col > div {
  background-color: $content-background;
}

.content-row,
.content-col,
.content-bg {
  background-color: $content-background;
}

.content-row,
.content-row-transparent {
  margin-left: 0;
  margin-right: 0;
  margin-top: 1px;
  padding: 15px;

  @media screen and (max-width: $break-small) {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  &.grid-row {
    height: 300px;
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  &.content-row-padded {
    padding: 10px;
  }

  &.title-row {
    h3 {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

.grid-row {
  .toolbar {
    height: 30px;
    line-height: 24px;
    padding: 3px;
    width: 100%;

    > div {
      margin-right: 5px;
    }
  }

  .ReactTable .rt-thead .grid-header-cell,
  .ReactTable .rt-thead.-filters .grid-header-cell {
    border-color: $background-color;

    input {
      color: $text-color;
    }
  }

  .ReactTable .grid-header-cell [class^='col-'] {
    padding: 0;
  }

  .ReactTable .grid-header-cell .date-filter input {
    height: 20px;
  }
}

.progress {
  margin-bottom: 0;
}

// gets it above modals
.rc-calendar-picker {
  z-index: 1050;
}

.flex-order {
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: $break-x-small) {
    .flex-xs-order-1 {
      order: 1;
    }

    .flex-xs-order-2 {
      order: 2;
    }

    .flex-xs-order-3 {
      order: 3;
    }

    .flex-xs-order-4 {
      order: 4;
    }

    .flex-xs-order-5 {
      order: 5;
    }

    .flex-xs-order-6 {
      order: 6;
    }
  }

  @media screen and (min-width: $break-small) {
    .flex-sm-order-1 {
      order: 1;
    }

    .flex-sm-order-2 {
      order: 2;
    }

    .flex-sm-order-3 {
      order: 3;
    }

    .flex-sm-order-4 {
      order: 4;
    }

    .flex-sm-order-5 {
      order: 5;
    }

    .flex-sm-order-6 {
      order: 6;
    }
  }

  @media screen and (min-width: $break-medium) {
    .flex-md-order-1 {
      order: 1;
    }

    .flex-md-order-2 {
      order: 2;
    }

    .flex-md-order-3 {
      order: 3;
    }

    .flex-md-order-4 {
      order: 4;
    }

    .flex-md-order-5 {
      order: 5;
    }

    .flex-md-order-6 {
      order: 6;
    }
  }
}

.content-col {
  padding: 10px;
}

.content-section {
  .content-col:not(:last-child) {
    margin-bottom: 10px;
  }
}

.fixed-bottom {
  bottom: 0;
  position: fixed;
  z-index: 10;

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .content-row {
    width: calc(100% - 30px);
  }
}

.overflow {
  overflow: auto;
}

.indented {
  overflow: hidden;
  padding-left: 10px;
  word-break: break-all;
}

.user-thumbnail-wrapper {
  display: inline-block;

  .admin-list & {
    margin-right: 5px;
    padding-bottom: 5px;
  }

  .user-thumbnail {
    border-radius: 50%;
  }

  .backup-user-thumbnail {
    background-color: $user-thumb-color;
    color: $text-color;
    border-radius: 50%;

    $font-size: 12px;
    font-weight: bold;
    font-size: $font-size;
    line-height: $font-size;
    text-align: center;
  }

  &.user-thumbnail-small .backup-user-thumbnail {
    $font-size: 8px;
    font-size: $font-size;
    line-height: $font-size;
  }
}

.user-thumbnail-popover {
  box-shadow: none;
  margin: 0;
  padding: 0;
  position: relative;

  .user-thumbnail-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popover-content {
    display: flex;

    p {
      margin-bottom: 2px;
    }
  }

  .popover-info {
    margin: 0;
    padding: 5px;

    .info-label {
      font-weight: 500;
    }
  }
}

.tab-control {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  h4 {
    color: $text-color;
    margin: 0;
    margin-bottom: 15px;
    padding-left: 15px;
  }

  a {
    color: $text-subtle-color;
    padding: 3px 10px;
  }

  .nav-tabs > li {
    > a {
      &:hover {
        background-color: $content-background;
        border-bottom: 0;
      }
    }

    &.active {
      > a {
        border: 0;
        color: $accent-color;

        &:focus,
        &:hover {
          border: 0;
          color: $accent-color;
        }
      }
    }
  }

  .active a {
    border: 0;
    color: $accent-color;
  }

  .nav-tabs {
    border-bottom: 0;
    padding-left: 5px;
  }

  &.double-tab-control {
    .nav {
      border-bottom: 1px solid $text-subtle-color;
    }

    .nav:first-of-type {
      margin-bottom: 5px;

      .active a {
        border: 1px solid $text-subtle-color;
        border-bottom-color: transparent;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.border-seperator {
  border-bottom: 1px solid $popover-border-color;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.md-editor {
  float: left;
  width: 100%;
}

.buttons-margin {
  .btn {
    margin-right: 3px;
  }
}

.btn-default {
  color: #333 !important;
  background-color: #fff;
  border-color: #ccc !important;
}

.Select.is-open {
  position: relative;
  z-index: 1000;
}

.data-modal {
  max-height: 90%;
  width: 90%;
  display: flex;

  .modal-content {
    flex: 1;
    overflow: auto;

    display: flex;
    flex-direction: column;
  }

  .modal-body {
    flex: 1;
    overflow: auto;
  }

  table {
    margin-left: -15px;
  }

  thead th {
    position: sticky;
    top: -15px;
    background-color: $content-background;
    box-shadow: 1px 1px 0 #ddd;
  }
}

.small-alert .alert {
  padding-top: 5px;
  padding-bottom: 5px;
}

.gapped-grid {
  > .row {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 0px;
  }

  .content-row {
    margin-left: 3px;
    margin-right: 3px;
    padding: 10px;
  }

  > .row > div {
    padding: 3px;
    // > div {
    //   padding: 3px;
    // }
  }

  .box-fixed-header {
    h4 {
      margin: 3px 0;
    }

    .breadcrumbs-wrapper {
      padding: 0;
    }
  }

  .breadcrumbs {
    color: $text-subtle-color;
    font-size: 0.8em;

    a {
      color: $text-subtle-color;
    }
  }
}

.explanation-row {
  .explanation {
    color: $secondary-text;
    background-color: $secondary-color;

    h4 {
      margin-top: 0;
    }

    a {
      color: $secondary-text;
      text-decoration: underline;
    }
  }
}
