#docs-content {
  h3 {
    margin-top: 0;
  }

  .docs-section {
    margin-bottom: 30px;

    &::after {
      content: '';
      clear: both;
      display: table;
    }
  }

  .docs-link {
    background-color: $secondary-color;
    color: $secondary-text;
    border-radius: 5px;
    padding: 20px 10px;
    margin-right: 5px;
    height: 150px;
    width: 150px;
    transition: top 0.5s;
    display: block;
    float: left;
    white-space: normal;
    // display: flex;
    // vertical-align: middle;

    &:hover {
      position: relative;
      top: -4px;
    }

    img {
      max-width: 130px;
      max-height: 110px;
    }

    &.docs-link-w-img {
      line-height: 110px;
      background-color: $background-color;
    }
  }
}

.documentation-markdown {
  img {
    max-width: 100%;
  }

  blockquote {
    font-size: 1em;
    background-color: $background-color;
  }
}
