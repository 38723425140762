.has-error {
  .rw-widget {
    border-color: #a94442;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;

    &.rw-state-focus {
      border-color: #a94442;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 6px #ce8483;
    }
  }
}
