.show-execution {
  .process-info {
    h3 {
      margin-right: 10px;
      margin-top: 0;
    }
  }

  .process-details {
    .progress {
      margin-bottom: 15px;
      margin-top: 5px;
    }

    h5 {
      margin-bottom: 0;
    }
  }

  .next-step {
    border-top: 1px solid $secondary-text;
    max-height: 40%;
    overflow: auto;
    padding-left: 25;

    .single-step & {
      max-height: calc(100% - 70px);
    }

    @media screen and (max-width: $break-large) {
      padding-left: 10px;
    }

    > div {
      overflow: auto;
    }

    hr {
      margin-bottom: 5px;
      margin-left: 0;
      margin-top: 5px;
      max-width: 500px;
      width: calc(100% - 20px);
    }

    .vault-select {
      width: 100%;
    }

    .control-label {
      height: 34px;
      line-height: 34px;
    }
  }

  .execution-info-button {
    float: right;
    margin-top: 0;
  }
}

.admin-list {
  .invite-user {
    margin-bottom: 5px;

    .btn {
      border-radius: 100%;
      height: 30px;
      padding-left: 9px;
      padding-top: 4px;
      width: 30px;

      &:focus {
        outline: 0;
      }
    }
  }
}

.current-step-header {
  line-height: 30px;
  margin-bottom: 0;
  margin-top: 0;

  .user-thumbnail-wrapper {
    margin-right: 5px;
  }
}

.execute-row {
  margin-bottom: 5px;

  .btn {
    margin-right: 5px;
  }
}

.image-preview {
  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.assign-user {
  hr {
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .assign-user-list {
    height: 200px;
    overflow-x: none;
    overflow-y: auto;
    width: 250px;
  }

  .user-option {
    border-color: transparent;
    line-height: 35px;
    margin-bottom: 3px;
    padding: 3px;
    width: 100%;

    &:hover {
      background-color: $accent-color;
      border-radius: 3px;
      cursor: pointer;
    }
  }
}

.step-ends {
  .step-header {
    align-items: center;
    display: flex;
    height: 40px;

    .step-name {
      font-size: 16px;
      font-weight: bold;
    }

    .time {
      color: $text-subtle-color;
      margin-left: 10px;
    }
  }
}

.execution-overview {
  display: flex;
  flex-direction: column;
  width: 100%;

  .header {
    margin-bottom: 10px;
  }

  &.closed {
    visibility: collapse;
    width: 0;
  }

  .info {
    .info-piece {
      display: inline-block;
      width: 50%;
    }

    .info-title {
      font-size: 18px;
    }
  }
}

// for loop automating
.child-processes {
  max-height: 150px;
  overflow: hidden;
  overflow-y: scroll;
}

.step-info {
  padding-top: 10px;

  .step-info-title {
    font-size: 20px;
  }

  .overview-step {
    padding: 10px 0;

    .step-title {
      font-size: 16px;
    }

    .step-type {
      color: $text-subtle-color;
      font-size: 12px;
    }

    .overview-step-desc-closed,
    .overview-step-desc-open {
      cursor: pointer;

      &::after {
        color: $accent-text-active;
        padding-left: 14px;
        position: relative;
      }
    }

    .overview-step-desc-closed {
      &::after {
        content: 'Show Description';
      }
    }

    .overview-step-desc-open {
      &::after {
        content: 'Hide Description';
      }
    }

    .overview-step-desc {
      padding-top: 6px;
    }
  }
}

.step {
  .step-completion {
    font-size: 14px;
    margin-top: 10px;
  }

  .step-type {
    color: $text-subtle-color;
    font-size: 10px;
  }

  .time {
    color: $text-subtle-color;
    display: flex;
    justify-content: flex-end;
    margin-left: 5px;
  }

  .step-admin {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .username {
      margin-right: 8px;
    }
  }

  hr {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.json-response {
  max-height: 300px;
  max-width: calc(100%);
  overflow-y: auto;
}

@import './Timeline';
@import './stepTypes/InputStep';
@import './stepTypes/VaultInputStep';
