.duo-onboarding {
  height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: auto;

  a {
    color: white;
    text-decoration: underline;
  }

  a.signout {
    color: white;
    text-decoration: underline;
    width: auto;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  #duo-explanation {
    button {
      color: white;
      margin-left: 15px;
      padding: 5px;
      border: 1px solid white;
      border-radius: 6px;
      background-color: transparent;

      &:hover {
        background-color: white;
        color: $pink;
      }
    }
  }

  .content {
    a {
      color: $text-color;
    }
  }

  .alert {
    padding: 5px 15px;
    margin-bottom: 5px;
  }

  #duo-jumbotron {
    position: relative;
  }
}
