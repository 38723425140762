.input-step-row {
  input:not([type='checkbox']) {
    max-width: 500px;
    width: calc(100% - 15px);
  }
}

.select-step-type-field {
  background-color: $clickable-read-only-field !important; // sass-lint:disable-line no-important

  &:hover {
    cursor: pointer;
  }
}
