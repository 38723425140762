// stylelint-disable-next-line selector-max-id
html,
body,
#root {
  background-color: $background-color;
  color: $text-color;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.nav-stacked {
  display: grid;
}

.jumbotron {
  padding: 48px 60px;
  border-radius: 6px;
  img {
    height: 50px;
  }

  p {
    margin-bottom: 15px;
    font-size: 21px;
    font-weight: 200;
  }
}

// for when they are inside something
.container {
  max-width: 100%;

  @media screen and (max-width: $break-small) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media screen and (min-width: $break-small) {
    padding-top: 15px;
  }

  @media screen and (min-width: $break-small) {
    width: 900px;
    max-width: 100%;
  }

  @media screen and (min-width: $break-medium) {
    width: 992px;
    max-width: 100%;
  }

  @media screen and (min-width: $break-large) {
    width: 1200px;
    max-width: 100%;
  }

  @media screen and (min-width: $break-x-large) {
    width: 1400px;
    max-width: 100%;
  }
}

.c-page-header {
  font-size: 1.3rem;
  margin: 5px 0;
}

.btn-xs .badge.badge-notify,
.badge-notify {
  background: $toast-badge-color;
  left: 10px;
  position: relative;
  top: -15px;
}

.navbar {
  background-color: $secondary-color;
  border-color: $secondary-color;
  border-radius: 0;
  color: $secondary-text;
  height: $top-bar-height;
  margin-bottom: 1px;

  // bootstrap override
  // stylelint-disable-next-line selector-max-compound-selectors
  &.navbar-default .navbar-nav > .active > a,
  &.navbar-default .navbar-nav > .active > a:hover,
  &.navbar-default .navbar-nav > .active > a:focus {
    background-color: $secondary-muted-color;
    color: $accent-text;
    margin-top: -1px;
  }

  // stylelint-disable-next-line selector-max-compound-selectors
  &.navbar-default .navbar-nav > li > a:hover,
  &.navbar-default .navbar-nav > li > a:focus {
    color: $accent-text;
  }

  .navbar-form {
    margin: 16px 0;
    padding-left: 15px;
    padding-right: 15px;

    @media screen and (max-width: $break-small) {
      float: left;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .nav-button {
    background-color: $secondary-color !important;
    border: 0;
    color: $secondary-text;
    font-size: 24px;
    outline: none;
    padding-top: 0;

    @media screen and (max-width: $break-small) {
      padding-left: 0;
    }

    &:active {
      background-color: inherit;
      box-shadow: none;
      outline: none !important;
    }

    &:hover {
      color: $accent-text;
    }
  }

  .user {
    .name {
      font-size: 1.3rem;
    }
    &:hover {
      .name {
        color: $accent-text;
      }
    }

    a {
      padding: 0;
    }

    .portrait {
      float: left;
      padding: 10px;
    }

    .name {
      float: left;
      line-height: 70px;
      @media screen and (max-width: $break-small) {
        display: none;
      }
    }
  }

  .active {
    .name {
      color: $accent-text;
    }
  }

  .navbar-right {
    @media screen and (max-width: $break-small) {
      float: right;
      margin-right: -15px;
    }
  }

  .navbar-nav {
    // stylelint-disable-next-line selector-max-compound-selectors
    li a {
      color: $secondary-text;
      font-size: 1.3em;
      line-height: calc(#{$top-bar-height} - 30px);
      @media screen and (max-width: $break-small) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .search-wrapper {
    @media screen and (max-width: $break-medium) {
      display: none;
    }
  }
}

.hide-customer {
  @media screen and (max-width: $break-large) {
    display: none;
  }
}

.navbar-customer {
  float: left;
  font-size: 18px;
  font-weight: bold;
  line-height: 70px;
}

.menu-customer {
  display: grid;
  font-size: 16px;
  font-weight: bold;
  padding: 20px 5px 0;
  text-align: center;
  @media screen and (min-width: $break-large) {
    display: none;
  }
}

.navbar-nav {
  @media screen and (max-width: $break-small) {
    margin: 0;
  }

  > li {
    @media screen and (max-width: $break-small) {
      float: left;
    }

    &:hover a {
      color: $accent-text;
    }

    a {
      @media screen and (max-width: $break-small) {
        padding-bottom: 15px;
        padding-top: 15px;
      }
    }
  }
}

.navbar-default {
  .navbar-form {
    border: 0;
    border-color: transparent;
    box-shadow: none;
    padding-left: 15px;
    padding-right: 15px;

    @media screen and (max-width: $break-small) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

.sign-out-text {
  @media screen and (max-width: $break-small) {
    display: none;
  }
}

.search-group {
  .form-control:last-child {
    border-radius: 4px;
  }

  .search-bar {
    background-color: $secondary-muted-color;
    border: 0;
    box-shadow: none;
    color: $secondary-text;
    outline: none;
    text-indent: 20px;

    &::placeholder {
      color: $secondary-text;
    }

    &:active {
      border: 0;
      box-shadow: none;
      outline: 0;
    }
  }

  .fa-search {
    color: $secondary-text;
    left: 10px;
    position: absolute;
    top: 10px;
    z-index: 10;
  }
}

.body {
  display: flex;
  flex-flow: row;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.side-bar {
  background: $secondary-color;
  color: $secondary-text;
  height: 100%;
  left: 0;
  position: relative;
  transition: left $animation-timing ease;
  width: $side-bar-width;
  z-index: 8;

  .nav-hide & {
    left: -$side-bar-width;
    transition: left $animation-timing ease;
  }

  .brand {
    background: $secondary-color;
    display: block;
  }

  .search-wrapper {
    margin: 20px 0 10px;

    .search-group {
      display: grid;
    }

    @media screen and (min-width: $break-medium) {
      display: none;
    }

    @media screen and (max-width: $break-medium) {
      .navbar-form {
        box-shadow: 0;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.marketing-brand {
  float: left;
}

.brand,
.marketing-brand {
  color: $accent-text;
  cursor: pointer;
  height: $top-bar-height;
  text-align: center;

  img {
    height: 40px;
    margin-top: ($top-bar-height - 40px) / 2;
  }
}

.navigation {
  height: calc(100% - #{$top-bar-height});
  overflow-y: auto;

  .nav {
    padding-bottom: 100px;

    & > li.active > a::before {
      top: 15px;
    }

    & > li {
      & > a {
        padding: 10px 15px;
        position: relative;
      }
    }
  }

  .nav-closed,
  .nav-open {
    &::after {
      font-family: FontAwesome;
      position: absolute;
      right: 15px;
      top: 10px;
    }
  }

  .nav-closed {
    &::after {
      content: "\f105";
    }
  }

  .nav-open {
    &::after {
      content: "\f107";
    }
  }

  a {
    color: $secondary-text;
    display: block;
    height: 100%;
    text-decoration: none;
    width: 100%;

    &:hover {
      color: $accent-text;
    }
  }

  li a {
    &:hover,
    &:focus {
      background-color: inherit;
    }
  }

  .active {
    a {
      color: $accent-text;

      &::before {
        color: $background-color;
        content: "\f0d9";
        display: block;
        font-family: FontAwesome;
        font-size: 20px;
        height: 27px;
        line-height: normal;
        position: absolute;
        right: -21px;
        top: 5px;
        width: 27px;
      }
    }
  }

  ul {
    display: block;
    list-style-type: none;
  }

  .side-bar-subtree {
    left: 0;
    padding-left: 0;
  }

  .list {
    padding-left: 20px;
    position: relative;

    li {
      position: relative;
    }
  }

  li {
    a,
    span {
      display: block;
      padding-left: 10px !important;
    }

    span {
      padding-bottom: 10px;
      padding-top: 10px;
      position: relative;

      &:hover {
        color: $accent-text;
      }
    }
  }

  li {
    cursor: pointer;
    display: list-item;
    font-size: 16px;
    line-height: 30px;
    margin: 0;
    padding: 0;
  }
}

.main {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  transition: left $animation-timing, width $animation-timing ease;
  width: 100%;
  z-index: 10;

  @media screen and (max-width: $break-small) {
    transition: left $animation-timing, width $animation-timing ease;
    width: 100%;
  }

  .logged-in & {
    left: $side-bar-width;
    transition: left $animation-timing, width $animation-timing ease;
    width: calc(100% - #{$side-bar-width});
  }

  .nav-hide & {
    left: 0;
    transition: left $animation-timing, width $animation-timing ease;
    width: 100%;
  }
}

.content {
  flex: 1;
  // 90px is breadcrumbs
  height: calc(100% - #{$top-bar-height});
  overflow-y: auto;
  width: 100%;

  .single-step & {
    height: calc(100% - #{$top-bar-height});
  }

  .box-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .grid-row,
    .box-rest {
      flex: 1;
      overflow-y: auto;
    }

    .box-fixed-footer {
      margin-bottom: 10px;
    }

    .box-fixed-footer,
    .box-fixed-header {
      flex: 0 0 auto;
    }
  }

  .box-row-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;

    .grid-row,
    .box-rest {
      flex: 1;
      overflow-y: auto;
    }

    .box-fixed-header {
      flex: 0 0 auto;
    }
  }
}

.overlay {
  align-items: center;
  background-color: $overlay-color;
  color: $text-subtle-color;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0.8;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  .message {
    text-align: center;
  }
}

@media screen and (max-width: $break-x-small) {
  .hide-on-mobile {
    display: none;
  }
}

.content-tabs {
  padding-top: 10px;
}

.collapsible-description-title {
  font-size: 18px;
}

.collapsible-description-closed,
.collapsible-description-open {
  cursor: pointer;

  &::after {
    color: $accent-text-active;
    padding-left: 12px;
    position: relative;
  }
}

.collapsible-description-closed {
  &::after {
    content: "Show";
  }
}

.collapsible-description-open {
  &::after {
    content: "Hide";
  }
}

.collapsible-description {
  max-height: 150px;
  overflow-y: auto;
}

.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eee;
}

.container .jumbotron,
.container-fluid .jumbotron {
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 6px;
}

.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
}

@media screen and (min-width: 768px) {
  .container .jumbotron,
  .container-fluid .jumbotron {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

.menu-container {
  position: relative;
}

.menu {
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: 65px;
  right: 0;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.1s ease, transform 0.1s ease, visibility 0.1s;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  z-index: 999;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #ddd;
  padding: 5px;
  font-size: 1.125rem;
}

.menu-trigger {
  background: #fff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.1s ease;
}

.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  margin: 0 10px;
}
