.help {
  cursor: help;
  display: inline-block;
  font-size: 18px;
  margin-left: 0.33em;
  vertical-align: middle;
}

.help-image {
  padding: 10px 50px;
}
