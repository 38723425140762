$pink: rgba(239, 0, 127, 1);

#home {
  height: 100%;
  width: 100%;
  overflow: auto;

  button {
    color: white;
    margin-left: 15px;
    padding: 5px;
    border: 1px solid white;
    border-radius: 6px;
    background-color: transparent;

    &:hover {
      background-color: white;
      color: $pink;
    }
  }

  .nav > li > a:hover,
  .nav > li > a:focus {
    text-decoration: none;
    background-color: transparent;
  }
}

#home-header {
  color: white;
  padding: 180px 0 100px;
  overflow: hidden;
  background: rgb(2, 0, 36);
  background: linear-gradient(170deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 1) 15%, $pink 100%);

  @media screen and (max-width: $break-x-large) {
    padding: 160px 0 60px;
  }

  @media screen and (max-width: $break-large) {
    padding: 100px 0 60px;
  }

  @media screen and (max-width: $break-medium) {
    padding: 50px 0 0;
  }

  > .container {
    position: relative;
  }

  a {
    color: white;
    text-decoration: underline;
  }
}

#home-toolbar {
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  top: -160px;
  z-index: 2000;
  width: 100%;
  padding-left: 10px;

  @media screen and (max-width: $break-x-large) {
    top: -150px;
  }

  @media screen and (max-width: $break-large) {
    top: -100px;
  }

  @media screen and (max-width: $break-medium) {
    top: -50px;

    .marketing-brand img {
      max-height: 30px;
    }
  }

  @media screen and (max-width: $break-x-small) {
    font-size: 0.9em;

    .marketing-brand img {
      max-height: 27px;
    }
  }

  .nav {
    flex: 1;

    > li {
      float: right;
      font-size: 1.2em;
      margin-left: 50px;

      @media screen and (max-width: $break-medium) {
        margin-left: 20px;
      }

      @media screen and (max-width: $break-x-small) {
        margin-left: 10px;
      }
    }

    a {
      text-decoration: none;
    }
  }
}

#home-hero {
  @media screen and (max-width: 850px) {
    text-align: center;
  }
  #home-hero-image {
    position: absolute;
    top: -70px;
    left: 0;
    width: 800px;

    @media screen and (max-width: $break-x-large) {
      top: -60px;
      width: 650px;
    }

    @media screen and (max-width: $break-large) {
      top: -10px;
      width: 550px;
    }
    @media screen and (max-width: $break-medium) {
      top: -10px;
      width: 450px;
    }
    @media screen and (max-width: $break-x-small) {
      width: 350px;
    }

    @media screen and (max-width: 850px) {
      position: static;
    }
  }

  #home-hero-content {
    margin-left: 850px;

    @media screen and (max-width: $break-medium) {
      text-align: center;
    }

    @media screen and (max-width: $break-x-large) {
      margin-left: 675px;
    }

    @media screen and (max-width: $break-large) {
      margin-left: 575px;
    }

    @media screen and (max-width: $break-medium) {
      margin-left: 470px;
    }

    @media screen and (max-width: 850px) {
      margin-left: 0px;
    }

    h2 {
      font-size: 3.5em;

      @media screen and (max-width: $break-large) {
        font-size: 2.8em;
      }

      @media screen and (max-width: $break-medium) {
        font-size: 2.2em;
      }
    }

    p {
      padding-left: 15px;
      font-size: 1.4em;

      @media screen and (max-width: $break-medium) {
        font-size: 1.2em;
      }
    }

    #home-hero-cta {
      padding-left: 15px;
      margin-top: 45px;

      > div:first-child {
        padding-top: 10px;

        @media screen and (max-width: $break-large) {
          padding-bottom: 10px;
        }
      }

      > div {
        float: left;

        @media screen and (max-width: $break-large) {
          float: none;
          text-align: center;
        }
      }

      @media screen and (max-width: $break-large) {
        margin-top: 25px;
        padding-left: 0px;
      }

      @media screen and (max-width: $break-medium) {
        margin-top: 15px;
      }
    }

    #home-hero-cta input {
      color: white;
      margin-left: 25px;
      padding: 10px;
      border: 1px solid white;
      border-radius: 6px;
      background-color: transparent;

      &::placeholder {
        color: white;
      }
    }

    #home-hero-cta button {
      padding: 10px;
    }
  }
}

#home-about-section {
  background-color: white;
  padding: 40px 0;

  > .container {
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: $break-medium) {
      display: block;
    }
  }

  #home-about-content {
    width: 400px;

    @media screen and (max-width: $break-medium) {
      margin-top: 15px;
      width: 100%;
    }
  }

  #home-about-animation {
    min-height: 250px;

    @media screen and (max-width: $break-medium) {
      text-align: center;
      margin-top: 20px;
    }
  }
}

#home-about-animation svg {
  width: 400px;
  max-width: 100%;
  height: 220px;
}

#home-integration-brag {
  background-color: #f4f4f4;

  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 30px;

  img {
    max-width: 150px;
    max-height: 40px;
    padding-left: 3px;
    padding-right: 3px;
  }
}

.home-common-header {
  h5 {
    color: $pink;
    margin-bottom: 0;
    font-size: 0.9em;
    font-weight: bold;
  }

  h3 {
    margin-top: 5px;
  }

  p {
    color: #7e7e7e;
  }
}

#home-marketplace-section {
  background-color: white;
  padding: 40px 0;
  text-align: center;

  > .container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  #home-marketplace-content {
    max-width: 800px;
  }

  #home-marketplace-partners {
    display: flex;
    justify-content: center;
    text-align: left;
    padding-top: 30px;
    padding-bottom: 30px;

    > div {
      width: 400px;
      background-color: $pink;
      color: white;
      padding: 20px;
      display: flex;
      flex-direction: row;

      > div {
        flex: 1;
      }

      img {
        width: 100px;
        margin-left: 20px;
      }

      h4 {
        margin-top: 0px;
      }

      p {
        color: white;
      }
    }
  }
}

#home-pricing-section {
  background-color: #f4f4f4;
  padding: 40px 0;
  text-align: center;

  > .container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  #home-pricing-content {
    max-width: 800px;
  }
}

footer {
  background-color: white;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 80px;
}
