$timeline-left: 475px;
$timeline-left-medium: 20px;

// @mixin timeline($left) {
//   position: relative;

//   &::before {
//     background-color: $accent-color;
//     content: ' ';
//     display: block;
//     height: 100%;
//     left: $left;
//     position: absolute;
//     top: 1px;
//     width: 4px;
//     z-index: 5;

//     @media screen and (max-width: $break-large) {
//       left: $timeline-left-medium;
//     }
//   }
// }

@mixin marker($radius) {
  background-color: $content-background;
  border: 2px solid $accent-color;
  border-radius: 100%;
  color: $accent-color;
  height: $radius;
  width: $radius;
  position: relative;

  i {
    line-height: 16px;
    font-size: 16px;
    margin-top: ($radius - 20px) / 2;
    margin-left: ($radius - 20px) / 2;
    width: 16px;
  }

  h3 {
    left: 0;
    margin: 0;
    position: absolute;
    text-align: center;
    top: ($radius - 19px) / 2;
    width: 100%;
  }
}

$comment-width: calc(100% - 155px);

.comment-wrapper {
  border-color: transparent;
  position: relative;

  &::before {
    border-left: 2px dotted $accent-color;
    content: ' ';
    display: block;
    height: calc(100% - 3px);
    left: 96px;
    position: absolute;
    top: 2px;
  }

  .comment-box {
    margin-left: 130px;
    width: $comment-width;

    .form-group {
      margin-bottom: 0;
    }
  }

  .formatting-hints {
    padding-top: 3px;
    padding-left: 35px;

    > * {
      float: left;
      font-size: 0.7em;
      height: 20px;
      line-height: 20px;
      margin-left: 10px;
      margin-right: 10px;
    }

    blockquote {
      padding-bottom: 0;
      padding-left: 10px;
      padding-top: 0;
      margin-bottom: 0;
    }
  }
}

.timeline {
  .timeline-event > div:nth-child(2) {
    position: relative;

    &::before {
      border-left: 2px solid $accent-color;
      content: ' ';
      display: none;
      height: calc(100% - 35px + 10px);
      left: 21px;
      position: absolute;
      top: 35px;
    }
  }

  .marker {
    @include marker(35px);
    margin: 0 5px;
  }

  .timeline-event:not(:last-child) {
    margin-bottom: 10px;

    & > div:nth-child(2)::before {
      display: block;
    }
  }

  .timeline-event:first-child {
    & > div:nth-child(2)::before {
      border-left-style: dotted;
    }
  }

  .timeline-event-spacer {
    height: 20px;
    position: relative;

    &::before {
      border-left: 2px dotted $accent-color;
      content: ' ';
      display: block;
      height: 29px;
      left: 96px;
      position: absolute;
      top: 1px;
    }
  }

  .timeline-timestamp {
    font-size: 0.9em;
    padding: 0 10px;
    width: 75px;
    text-align: center;
  }

  .timeline-title {
    margin: 8px 10px 0 10px;
  }

  .active-trundle {
    padding: 0 10px;
  }

  .fa-stack {
    width: 30px;
    height: 30px;
    line-height: 30px;

    .badge {
      top: -9px;
      left: 14px;
      background: $toast-badge-color;
    }
  }

  .fa-stack-1x {
    font-size: 15px;
  }

  .fa-stack-2x {
    font-size: 30px;
  }
}

.comment {
  .comment-body {
    margin: 2px 0;
  }

  .comment-header {
    display: flex;

    .left {
      height: 100%;
    }

    .middle {
      flex: 1;
      height: 100%;
      padding-left: 5px;
    }

    .right {
      height: 100%;
      padding-left: 10px;
      width: 30px;
    }

    .time {
      color: $text-subtle-color;
      margin-left: 5px;
    }

    .options {
      border: 0;
      outline: none;

      &:hover {
        background-color: transparent;
        color: $accent-color;
      }

      &:focus {
        box-shadow: none;
        color: $accent-color;
      }

      &:hover:focus {
        background-color: $background-color;
      }
    }
  }

  .update-comment {
    margin-top: 10px;
    width: 100%;
  }

  .edit-info {
    color: $text-subtle-color;
    float: right;
    font-size: 12px;
    margin-top: 10px;
  }

  .like {
    float: left;
    margin-right: 10px;
  }

  .like-button {
    background: none;
    border: 0;
    color: $text-subtle-color;
    display: inline-block;
    outline: none;
    padding: 10px 0;

    &:hover {
      color: $text-color;
      cursor: pointer;
    }

    .like-count {
      font-size: 12px;
      margin-left: 3px;
    }

    &.liked {
      color: $like-color;
    }
  }
}
