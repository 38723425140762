.process-form-step-head {
  .btn-xs,
  .btn-group-xs > .btn {
    font-size: 1.1em;
    padding: 0;
  }
}

.process-form-step {
  &:not(.initiation-form-step) {
    border-top: 1px solid $background-color;
    padding-top: 10px;
  }

  .panel-body > div[class^='col-'],
  .panel-body > div[class*=' col-'] {
    padding: 0;
  }

  .panel-group {
    margin-bottom: 5px;
  }
}

.process-form {
  fieldset {
    margin-top: 10px;
  }

  .input-group {
    width: 100%;
  }

  .step-description {
    .control-label {
      padding-top: 0;
    }
  }

  .step-name {
    .row {
      margin-bottom: 5px;
    }
  }

  .step-row {
    .col-xs-1,
    .col-sm-1,
    .col-md-1,
    .col-lg-1,
    .col-xs-2,
    .col-sm-2,
    .col-md-2,
    .col-lg-2,
    .col-xs-3,
    .col-sm-3,
    .col-md-3,
    .col-lg-3,
    .col-xs-4,
    .col-sm-4,
    .col-md-4,
    .col-lg-4,
    .col-xs-5,
    .col-sm-5,
    .col-md-5,
    .col-lg-5,
    .col-xs-6,
    .col-sm-6,
    .col-md-6,
    .col-lg-6,
    .col-xs-7,
    .col-sm-7,
    .col-md-7,
    .col-lg-7,
    .col-xs-8,
    .col-sm-8,
    .col-md-8,
    .col-lg-8,
    .col-xs-9,
    .col-sm-9,
    .col-md-9,
    .col-lg-9,
    .col-xs-10,
    .col-sm-10,
    .col-md-10,
    .col-lg-10,
    .col-xs-11,
    .col-sm-11,
    .col-md-11,
    .col-lg-11,
    .col-xs-12,
    .col-sm-12,
    .col-md-12,
    .col-lg-12 {
      padding-left: 5px;
      padding-right: 5px;
    }

    .row {
      margin-left: -5px;
      margin-right: -5px;
    }

    .panel-body {
      padding: 10px;
    }
  }

  .step-option-buttons {
    margin-top: 10px;
  }

  .marker {
    @include marker(50px);
    // for drag
    cursor: move;

    left: 15px;
    top: 25px;

    h3 {
      height: 100%;
      line-height: 45px;
      top: 0;
    }

    .marker-handle {
      display: none;
    }

    &:hover {
      .marker-label {
        display: none;
      }

      .marker-handle {
        display: block;
      }
    }
  }

  .button-section {
    padding-left: 45px;
    padding-top: 70px;

    i {
      font-size: 20px;
    }
  }
}

.process-pre-fill {
  .template-input-panel {
    bottom: 14px;
  }

  .modal-dialog {
    width: 95%;
    max-width: 95%;
  }

  .offset-radio {
    margin-left: 5px;
    margin-top: 12px;
  }
}

.merge-attribute-wrapper {
  max-height: 300px;
  overflow: scroll;
}

.merge-attribute div {
  overflow: hidden;
  word-wrap: break-word;
}
