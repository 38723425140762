.integration-thumb {
  // min-height: 350px;
  // text-align: center;

  h3 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .img {
    position: relative;
    float: left;
    width: 100%;
    min-height: 65px;
    max-height: 125px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.integration-step-types {
  h1 {
    font-size: 18px;
    font-weight: bold;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
  }
}
